import { Box, Container, Flex, Heading, Text, VStack, Image } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../../components/Layout';
import { ProjectsSection } from '../../../components/sections/ProjectsSlider';
import SEO from '../../../components/seo';
import {
  ContactUsBannerWithForm,
  Hero,
  MainServiceBanner,
  SpecialtyServiceCard,
  SubHeroIcons,
} from '../../../components/shared';
import { usePaitingService } from '../../../GraphQL/Queries/usePaitingService';

const Painting = ({ location }) => {
  const data = usePaitingService();

  const {
    acf_service_info,
    acf_text_block_title,
    acf_image_text_block,
    acf_quote,
    acf_services_block,
    acf_projects_slider,
  } = data.serviceBy;

  const iconsSection = [
    {
      title: 'MULTI-FAMILY BUILDINGS',
      icon: '/icons/housing.svg',
    },
    {
      title: 'HIGH-RISES',
      icon: '/icons/condo.svg',
    },

    {
      title: 'RETAIL STORES',
      icon: '/icons/retail.svg',
    },
  ];
  return (
    <Layout location={location}>
      <SEO title="Painting" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.icon.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHeroIcons
        heading={acf_text_block_title.title}
        body={acf_text_block_title.content}
        iconSection={iconsSection}
      />

      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content}
      />

      <Container mt="10rem" mb="3rem" pb="3rem" display={{ base: 'none', md: 'block' }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} pl={{ base: '2rem', md: 'unset' }}>
          <Heading
            textStyle="servicePageHeading"
            color="primary"
            borderRight={{ base: '0', md: '2px' }}
            borderColor="primary"
            mr="2rem"
            w={{ base: 'unset', md: '17%' }}
          >
            {acf_image_text_block.blockList[1].title}
          </Heading>
          <Box w={{ base: 'unset', md: '60%' }} mt={{ base: '1rem', md: 'unset' }}>
            <Text textStyle="servicePageText">
              {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
            </Text>
          </Box>
        </Flex>
      </Container>
      <Flex flexWrap={{ base: 'wrap-reverse', md: 'unset' }} mb="5rem" w={{ base: '95%', md: 'unset' }} margin="0 auto">
        <VStack
          align="flex-end"
          justify="flex-start"
          spacing="2rem"
          flex={{ md: '1' }}
          mr={{ md: '6rem' }}
          w="100%"
          px={{ base: '1rem', md: 'unset' }}
        >
          <Box w={{ base: '100%', md: '560px' }} h={{ base: '400px', md: '437px' }}>
            <SpecialtyServiceCard
              fitContainer
              title={acf_services_block.servicesList[0].serviceTitle}
              image={acf_services_block.servicesList[0].mainImage.sourceUrl}
              description={acf_services_block.servicesList[0].serviceDescription}
            />
          </Box>
          <Box w={{ base: '100%', md: '560px' }} h={{ base: '400px', md: '437px' }}>
            <SpecialtyServiceCard
              fitContainer
              title={acf_services_block.servicesList[1].serviceTitle}
              image={acf_services_block.servicesList[1].mainImage.sourceUrl}
              description={acf_services_block.servicesList[1].serviceDescription}
            />
          </Box>
          <Box w={{ base: '100%', md: '560px' }} h={{ base: '400px', md: '437px' }}>
            <SpecialtyServiceCard
              fitContainer
              title={acf_services_block.servicesList[2].serviceTitle}
              image={acf_services_block.servicesList[2].mainImage.sourceUrl}
              description={acf_services_block.servicesList[2].serviceDescription}
            />
          </Box>
          <Box w={{ base: '100%', md: '560px' }} h={{ base: '500px', md: '437px' }}>
            <SpecialtyServiceCard
              fitContainer
              title={acf_services_block.servicesList[3].serviceTitle}
              image={acf_services_block.servicesList[3].mainImage.sourceUrl}
              description={acf_services_block.servicesList[3].serviceDescription}
            />
          </Box>
        </VStack>

        <Box>
          <Box
            flex="1"
            position={{ md: 'sticky' }}
            right="0"
            top="100px"
            boxSize={{ md: '45vw' }}
            justifySelf="flex-end"
          >
            <Image src="/images/BoomMan2.png" h="100%" w="100%" />
          </Box>

          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            pl={{ base: '1rem', md: 'unset' }}
            display={{ md: 'none' }}
            mt="-2rem"
            mb="2rem"
            pr={{ base: '1rem', md: 'unset' }}
          >
            <Heading
              fontSize="35px"
              color="primary"
              borderRight={{ base: '0', md: '2px' }}
              borderColor="primary"
              mr="2rem"
              w={{ base: 'unset', md: '17%' }}
            >
              {acf_image_text_block.blockList[1].title}
            </Heading>
            <Box w={{ base: 'unset', md: '60%' }} mt={{ base: '1rem', md: 'unset' }}>
              <Text textStyle="servicePageText">
                In every remodeling project, we make sure our services are performed within the highest quality
                standards in the industry. Our residential remodeling services include:
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box mt="5rem" />
      <ProjectsSection projects={acf_projects_slider.projectList} />
      <Box mb={{ md: '20rem' }}></Box>

      <ContactUsBannerWithForm
        isCommercial
        lightBackground
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default Painting;
